import React from 'react'
import photo1 from '../assets/images/persons/katarzyna-ciszewska-rybczynska.jpg'
import photo2 from '../assets/images/persons/michalina-rykun.jpg'
import photo3 from '../assets/images/persons/bernard-zdrozny.jpg'
import photo4 from '../assets/images/persons/fryderyk-zielinski.jpg'

export const team = [
  {
    id: '01',
    photo: photo1,
    name: 'Katarzyna',
    lastName: 'Ciszewska-Rybczyńska',
    job: 'Lekarz stomatolog',
    content: (
      <>
        <p>
          Lekarz stomatolog z wieloletnim stażem w dziedzinie protetyki,
          endodoncji i leczeniu zachowawczym. Swoją praktykę stomatologiczną
          prowadzi od 1995r. Ukończyła Sofijską Akademię Medyczną, odbyła liczne
          staże i szkolenia między innymi w Wojewódzkim Szpitalu w Płocku, w
          Centralnym Szpitalu Klinicznym przy ul. Banacha w Warszawie oraz w
          prywatnej klinice przy ul. Podwale w Warszawie.
        </p>
        <p>
          Odbyła staż w Klinice Trummera w Szwajcarii oraz uczestniczyła w
          licznych kursach i szkoleniach w zakresie protetyki i ortodoncji
          (Filozofia Clear-Aligner by Pablo Echarri). Jest absolwentką studiów
          podyplomowych w Akademii Medycznej w Warszawie (2010r). Współpracuje z
          kilkoma klinikami stomatologicznymi. Nagradzana coroczną nagrodą Orłów
          Stomatologii.
        </p>
      </>
    ),
  },
  {
    id: '02',
    photo: photo2,
    name: 'Michalina',
    lastName: 'Rykun',
    job: 'Lekarz dentysta',
    content: (
      <>
        <p>
          Absolwentka Pomorskiego Uniwersytetu Medycznego. Staż odbyła w
          Warszawskiej Akademi Medycznej. Uczestniczka licznych kongresów i
          kursów stomatologicznych z dziedziny stomatologii zachowawczej,
          endodoncji i protetyki. Odbyła 3 miesięczny staż w Niemczech
          (Monachium) w specjalistycznym centrum ortodoncji oraz miesięczne
          szkolenie w klinice implantologicznej w Baden-Baden(Niemcy).
        </p>
        <p>
          W pracy zawodowej zajmuje się przede wszystkim leczeniem
          zachowawczym,endodontycznym i protetycznym.
        </p>
      </>
    ),
  },
  {
    id: '03',
    photo: photo3,
    name: 'Bernard',
    lastName: 'Zadrożny',
    job: 'Implantolog',
    content: (
      <>
        <p>Lekarz stomatolog ze specjalizacją z zakresu implantologii.</p>
        <p>
          Ukończył studia na Wydziale lekarskim oddziale stomatologicznym
          Akademii Medycznej w Warszawie. Od 1999 roku czynnie zajmuje się
          implantologią stomatologiczną.
        </p>
        <p>
          Jest członkiem Ogólnopolskiego Stowarzyszenia Implantologii
          Stomatologicznej.
        </p>
        <p>
          W 2009 roku uzyskał tytuł implantologa na Uniwersytecie we
          Frankfurcie. Uczestniczył w wielu konferencjach i kursach w Polsce i
          za granicą m.in. w Szwecji, Niemczech i Stanach Zjednoczonych. W 2012
          ukończył studia magisterskie o profilu implantologicznym na
          Uniwersytecie we Frankfurcie.
        </p>
        <p>
          Posiada doświadczenie kliniczne w wielu systemach implantologicznych :
          (Ankylos, 3i, Straumann, Xive, MIS,Astra, Alpha-Bio, Megagen,
          Branemark,Cresco,Pitteasy, TBR, Zimmer, Bego).
        </p>
        <p>
          Główne zainteresowania w pracy związane są z tematyką zaawansowanej
          chirurgii implantologicznej, technik augmentacyjnych oraz różnych
          sposobów rehabilitacji protetycznej pacjenta.
        </p>
      </>
    ),
  },
  {
    id: '04',
    photo: photo4,
    name: 'Fryderyk',
    lastName: 'Zieliński',
    job: 'Lekarz dentysta',
    content: (
      <>
        <p>
          Absolwent Wydziału Lekarsko-Stomatologicznego, Warszawskiego
          Uniwersytetu Medycznego.
        </p>
        <p>
          Nauczyciel akademicki w Zakładzie Chirurgii Stomatologicznej Wydziału
          Lekarsko-Stomatologicznego WUM. Stale poszerza swoją wiedzę z zakresu
          chirurgii stomatologicznej uczestnicząc w licznych szkoleniach oraz
          konferencjach naukowych. W swojej praktyce zajmuje się głównie
          zabiegami z zakresu chirurgii stomatologicznej.
        </p>
      </>
    ),
  },
]
