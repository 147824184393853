import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div``

const StyledItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  padding: 30px 0;

  @media (min-width: ${({ theme }) => theme.media.tablet}) {
    grid-template-columns: 1fr 2fr;
    &:nth-child(even) {
      grid-template-columns: 2fr 1fr;
      > div:nth-child(1) {
        order: 2;
      }
    }
  }
`

const StyledInfo = styled.div`
  display: block;
  margin: 0 auto 30px;
  width: 60%;
  aspect-ratio: 1 / 1.2;
  border-radius: 3px;
  background: url(${({ photo }) => photo}) no-repeat;
  background-size: cover;
  background-position: top center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  @media (min-width: ${({ theme }) => theme.media.tablet}) {
    margin: 0;
    width: 100%;
  }
`

const StyledBio = styled.div`
  h3 {
    margin: 0 0 5px;
    font-size: 20px;
    font-weight: 700;
  }

  h4 {
    margin: 0 0 10px;
    font-size: 18px;
  }

  p {
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 1.33;
  }
`

const Persons = ({ data }) => {
  return (
    <StyledWrapper>
      {data.map(person => (
        <StyledItem>
          <StyledInfo photo={person.photo} />
          <StyledBio>
            <h3>
              {person.name} {person.lastName}
            </h3>
            <h4>{person.job}</h4>
            {person.content}
          </StyledBio>
        </StyledItem>
      ))}
    </StyledWrapper>
  )
}

export default Persons
