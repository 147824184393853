import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'
import Button from '../components/Button/Button'
import Persons from '../components/Persons'

import { team } from './../constants/team'

const TeamPage = ({ data }) => (
  <Layout
    header="Nasz zespół"
    headerBold=""
    homePage
    asideContent={[
      <AsideImage
        key="asideImage"
        srcImage={data.coverPhoto.childImageSharp.fluid}
      >
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę
        </Button>
      </AsideImage>,
    ]}
  >
    <SEO title="Nasz Zespół | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <Persons data={team} />
  </Layout>
)

export default TeamPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
